'use client';

import Link from 'next/link';
import {useHover, usePress} from 'react-aria';
import {twJoin, twMerge} from 'tailwind-merge';
import getImageAltText from 'src/utils/getImageAltText';
import formatPlaceType from 'src/utils/formatPlaceType';
import PlaceCardDropdown from './PlaceCardDropdown';
import getImageUrl from 'src/utils/getImageUrl';
import SmallRating from './SmallRating';
import FadingImage from './FadingImage';
import Skeleton from './Skeleton';

const placeCardsContainerClassName = {
  5: [
    'grid w-full gap-7 gap-y-10 max-[800px]:gap-5 max-[620px]:gap-4 grid-cols-[repeat(5,minmax(0,1fr))]',
    'max-[1300px]:grid-cols-[repeat(4,minmax(0,1fr))]',
    'max-[900px]:grid-cols-[repeat(3,minmax(0,1fr))]',
    'max-[620px]:grid-cols-[repeat(2,minmax(0,1fr))]'
  ].join(' '),
  7: [
    'grid w-full gap-6 max-[800px]:gap-4 grid-cols-[repeat(7,minmax(0,1fr))]',
    'max-[1300px]:grid-cols-[repeat(6,minmax(0,1fr))]',
    'max-[1000px]:grid-cols-[repeat(5,minmax(0,1fr))]',
    'max-[900px]:grid-cols-[repeat(4,minmax(0,1fr))]',
    'max-[650px]:grid-cols-[repeat(3,minmax(0,1fr))]',
    'max-[520px]:grid-cols-[repeat(2,minmax(0,1fr))]'
  ].join(' ')
};

const placeCardsContainerRowClassName = {
  5: [
    'max-[1300px]:[&>*:nth-child(4)]:hidden',
    'max-[900px]:[&>*:nth-child(3)]:hidden',
    'max-[620px]:[&>*:nth-child(n+4)]:block'
  ].join(' '),
  7: [
    'max-[1300px]:[&>*:nth-child(6)]:hidden',
    'max-[1000px]:[&>*:nth-child(5)]:hidden',
    'max-[900px]:[&>*:nth-child(4)]:hidden',
    'max-[650px]:[&>*:nth-child(n+5)]:block'
  ].join(' ')
};

type GridProps = {
  children: React.ReactNode;
  oneRow?: boolean;
  columns?: 5 | 7;
  className?: string;
};

export const PlaceCardsGrid = ({children, oneRow, columns = 5, className}: GridProps) => {
  return (
    <div
      className={twMerge(
        placeCardsContainerClassName[columns],
        oneRow ? placeCardsContainerRowClassName[columns] : null,
        className
      )}
    >
      {children}
    </div>
  );
};

const imagesSizes = {
  5: '(max-width: 620px) 50vw, (max-width: 900px) 33vw, (max-width: 1300px) 25vw, (max-width: 1600px) 20vw, 300px',
  7: '(max-width: 520px) 50vw, (max-width: 650px) 33vw, (max-width: 900px) 25vw, (max-width: 1000px) 20vw, (max-width: 1300px) 17vw, (max-width: 1600px) 15vw, 200px'
};

export const PlaceCardSkeleton = () => {
  return (
    <div className="relative flex flex-col rounded-2xl">
      <div className="transition relative rounded-2xl overflow-hidden aspect-[1.3]">
        <Skeleton className="w-full h-full my-0" />
      </div>

      <div className="place-text flex flex-col">
        <Skeleton className="h-5 w-[80%] mt-3" />

        <div className="flex mb-[10px]">
          <Skeleton className="h-4" />
        </div>
      </div>
    </div>
  );
};

type Props = {
  place: Place;
  showCountry?: boolean;
  columns?: 5 | 7;
  prefix?: string | React.ReactNode;
  priority?: boolean;
  session: IronSession | null;
};

const PlaceCard = ({place, showCountry = true, columns = 5, prefix, priority, session}: Props) => {
  const image = place.images?.[0];
  const imageUrl = image && getImageUrl(image.path, 'thumb');

  const {pressProps, isPressed} = usePress({preventFocusOnPress: true});
  const {hoverProps, isHovered} = useHover({});

  return (
    <article {...hoverProps} className="relative group flex flex-col rounded-2xl">
      <div
        className={twJoin(
          'place-image relative rounded-2xl overflow-hidden bg-[--subtle-color] aspect-[1.3] transition-transform duration-100',
          isPressed ? '[transform:translate3d(0,0,0)_scale(0.99)]' : '',
          isHovered &&
            'shadow-[0_3px_16px_rgb(23_10_37_/_0.02),0_20px_50px_rgb(23_10_37_/_0.04)] [transform:translate3d(0,-1px,0)_scale(1.02)]'
        )}
      >
        {imageUrl ? (
          <FadingImage
            src={imageUrl}
            alt={getImageAltText(image, place.name, place.countryName)}
            fill
            sizes={imagesSizes[columns]}
            className="object-cover"
            priority={priority}
          />
        ) : null}
      </div>

      <div className="place-text flex flex-col">
        <Link
          {...pressProps}
          href={`/${place.countryCode}/${place.nameId}`}
          className="expand-link font-bold truncate pt-3 max-[500px]:pt-2 text-xl max-sm:text-lg after:ring-offset-2 after:ring-gray-200 after:focus-visible:ring-1 after:rounded-2xl"
        >
          {prefix}
          {place.name}
        </Link>

        <div className="flex mb-2">
          <SmallRating rating={place.placeRatings?.mainRating} className="mr-2" />
          <span className="text-sm text-[--gray-text] truncate">
            {formatPlaceType(place.placeType)} {showCountry ? `in ${place.countryName}` : ''}
          </span>
        </div>
      </div>

      <PlaceCardDropdown place={place} session={session} />
    </article>
  );
};

export default PlaceCard;

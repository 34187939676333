'use client';

import {ImageProps} from 'next/image';
import {twMerge} from 'tailwind-merge';
import {useEffect, useRef, useState} from 'react';
import FastImage from './FastImage';

type Props = ImageProps & {};

const FadingImage = (props: Props) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [loaded, setLoaded] = useState<'initial' | 'finished' | boolean>('initial');

  useEffect(() => {
    if (!imageRef.current?.complete) setLoaded(false);

    // Checking for className here because if there are no custom styles,
    // there is no risk of transition duration clash.
    if (props.className && loaded === true) {
      const timeout = setTimeout(() => setLoaded('finished'), 1100);

      return () => clearTimeout(timeout);
    }
  }, [loaded]);

  return (
    <FastImage
      {...props}
      ref={imageRef}
      className={twMerge([
        loaded ? 'opacity-100' : 'opacity-0',
        props.className,
        loaded === true ? 'transition duration-1000' : null
      ])}
      onLoad={() => {
        setLoaded(true);
      }}
    />
  );
};

export default FadingImage;

import {FetcherOptions} from './fetcher';

export const getListQuery = (
  userNameId: string,
  listNameId: string,
  options: {loadAllImages?: boolean; numberOfImagesToLoad?: number; skipArticleFields?: boolean} = {}
) => {
  return {
    url: `/api/lists/${userNameId}/${listNameId || 'visited-places'}`,
    query: options,
    options: {next: {revalidate: 0}}
  };
};

export const getCountryQuery = (countryCode: string) => {
  return {
    url: `/api/countries/${countryCode}`,
    options: {next: {revalidate: 120}}
  };
};

export const myListsQuery: FetcherOptions = {url: '/api/lists/my-lists', options: {next: {revalidate: 0}}};

export const getMyReviewQuery = (countryCode: string, placeNameId: string, userId: string | undefined) => ({
  url: `/api/reviews`,
  query: {countryCode, placeNameId, user: userId, limit: 1}
});

export const getPlaceClimateQuery = (coordinates: number[] | undefined) => ({
  url: '/api/places/climate',
  query: {lat: coordinates?.[1], long: coordinates?.[0]}
});

export const getReviewsQuery = (countryCode: string, placeNameId: string) => ({
  url: '/api/reviews',
  query: {countryCode, placeNameId, withText: true, populate: 'user'},
  options: {next: {revalidate: 0}}
});

export const getNearbyPlacesQuery = (coordinates: number[], placeId: string) => ({
  url: '/api/places/nearby',
  query: {lat: coordinates[1], long: coordinates[0], placeId},
  options: {next: {revalidate: 0}}
});
